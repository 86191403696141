import { render, staticRenderFns } from "./ServicesForOfficial.vue?vue&type=template&id=17d56752&scoped=true&functional=true"
import script from "./ServicesForOfficial.vue?vue&type=script&lang=js"
export * from "./ServicesForOfficial.vue?vue&type=script&lang=js"
import style0 from "./ServicesForOfficial.vue?vue&type=style&index=0&id=17d56752&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "17d56752",
  null
  
)

export default component.exports
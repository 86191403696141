
import { TheMask } from 'vue-the-mask'
import { Component, Prop, Ref } from 'nuxt-property-decorator'
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import ErrorGrzForm from '@pp-frontend/shared-components/components/search-form/ErrorGrzForm.vue'
import DocGrz from '@pp-frontend/shared-packages/classes/doc-value/DocGrz'
import GrzGibddSearch from '@pp-frontend/shared-packages/classes/landing/gibdd/GrzGibddSearch'
import LicencePlateInput from '../LicencePlateInput.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchEmailField from '~/components/search/SearchEmailField.vue'
import SearchFormOfertaText from '~/components/search/SearchFormOfertaText.vue'

@Component({
  components: {
    SearchFormOfertaText,
    SearchEmailField,
    TheMask,
    LicencePlateInput,
    ErrorGrzForm
  }
})
export default class extends SearchFormAbstract {
  @Ref() ctcField!: HTMLInputElement
  @Prop() dataErrorFormValidations!: ErrorValidation
  USER_EMAIL_REQUIRED = true
  clientErrorValid: string = ''
  errorCode = 0
  showSubmitBtnSpinner: boolean = false
  formData = new DocGrz()

  errorClientValid () {
    this.clientErrorValid = ''
    this.$v.$touch()

    if (!this.dataErrorFormValidations.isError) {
      // @ts-ignore
      if (this.$v.formData.document_value.$error) {
        this.clientErrorValid = ' гос. номеров: <span class="error-msg__item">A 000 AA 000</span> <span class="error-msg__item">A 000 AA 00</span>'
      }
    }
  }

  getType (): string {
    return 'gibdd'
  }

  afterSubmitBehavior (): Promise<any> {
    this.showSubmitBtnSpinner = true
    return new GrzGibddSearch().validate(this.formData).then(() => {
      this.errorCode = 0
      this.showSubmitBtnSpinner = false
      this.$router.push({
        path: '/search/' + this.getType(),
        query: {
          document_type: this.formData.docType(),
          lead_source_id: window.$nuxt.$route.query.lead_source_id,
          ...this.formData
        }
      })
    }).catch((err) => {
      this.errorCode = err.response.status
      this.showSubmitBtnSpinner = false
    })
  }
}
